.close-arrow .Appkit4-icon {
    font-size: 13px;
}

.modal-header {
    display: inline-block;
    position: absolute;
    width: 272px;
    height: 29px;
    left: 25px;
    top: 30px;
    font-family: 'PwC Helvetica Neue';
    font-style: normal;
    font-weight: 420;
    font-size: 24px;
    line-height: 29px;
    color: #252525;
    margin-bottom: 20px;
}

.notification-list {
    position: absolute;
    overflow-x: hidden;
    width: auto;
    height: 620px;
    padding-top: 20px;
    margin-top: 40px;
    margin-left: -20px;
    display: flex;
    flex-direction: column;

}

.all-read-button {
    display: inline-block;
    position: absolute;
    width: 112px;
    height: 17px;
    left: calc(50% - 112px/2 + 120px);

    cursor: pointer;
    font-family: 'PwC Helvetica Neue';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    text-align: center;
    text-transform: capitalize;

    color: #EB1C2C;
    padding-top: 10px;
    padding-bottom: 20px;
}

.load-more-button {
    position: absolute;
    cursor: pointer;
    font-family: 'PwC Helvetica Neue';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 17px;
    margin-top: 680px;
    margin-left: 150px;
    color: #EB1C2C;
}

.no-notification {
    margin-left: 145px;
    margin-top: 50px;
    font-family: 'PwC Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #555555;
}

.notification-modal {
    width: 420px;
    height: 100vh;
    position: fixed;
    margin: auto;
    right: 0px;
}