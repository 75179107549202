.question-cell-container {
    width:100%;
    height: 100%;

}

.question-cell-container  .question-cell {
    width:100%;
    height:200px;
    border-style: solid;
    border-width: 1px;
    min-width: 150px;
}

.question-cell-container  .column-header{
    display: flex;
    justify-content:left;
    height: 150px;
    border-style: solid;
    border-width: 1px;
    /* background: #F3F3F3;*/
    background: #EB1C2C;
    color: #FFFFFF;
    padding: 10px;
}


.question-cell-container  .cell-text {
    width: 100%;
    height: 100%;
    padding: 5px;
}

.question-cell-container  .cell-number {
    width: 100%;
    height:2.74rem;
    /* height: 100%; */
    padding: 5px;
    border-style: solid;
    border-width: 1px;
}

.cell-label-center {
    display: flex;
    height: 100%;
    width:100%;
    justify-content: center;
    align-items: center;
}

.column-header > a {
    color: white;
}


.strategic-container .column-header  .icon-help-question-outline {
    color: white;
    text-decoration: none;

}