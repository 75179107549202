

.request-modal-body-container{
  width: 500px;
  margin-bottom: 20px;
  font-weight: 400;
font-size: 14px;
line-height: 16px;

/* Pembina black */

color: #221E1F;
}

.request-modal-header-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */
    
    text-align: center;
    
    /* Pembina black */
    
    color: #221E1F;
}

.request-modal-confirm-button {
    margin-left: 10px;
    margin-top:5px;
}

.message-input {
    width: 100%;
    background: #FFFFFF;
/* Core/Light Grey */

border: 1px solid #DEDEDE;
border-radius: 4px;
min-height: 100px;
margin-top: 20px ;
}