@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@appkit4/styles/appkit.min.css';
@import '@appkit4/react-components/dist/styles/appkit4-react.min.css';

body {
  margin: 0;
  height: 100%;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'PwC Helvetica Neue',
    sans-serif; */
  font-family: 'PwC Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@layer components {
   .i-circle {
    @apply bg-[#49ABA0] pt-[2px] px-[8px] text-white sm:px-[5px] sm:py-[2px] rounded-[50%] sm:text-[20px] sm:max-h-[38px] sm:mt-[2px] text-sm max-h-[24px] mt-[2px];
  } 
   .i-circle-user {
    @apply bg-[#49ABA0]  pl-[7px] pr-[7px] pt-[3px] pb-[4px] text-white  rounded-[50%] text-[15px]  mt-[2px] max-h-[26px] ;
  } 
  .i-circle-mobNav {
    @apply text-lg text-white rounded-full bg-[#49ABA0] flex items-center justify-center h-[30px] w-[30px]
  }
 .i-circle-header {
    @apply bg-[#49ABA0] pl-[7px] pr-[7px] pt-[0px] pb-[4px] text-white rounded-[50%] text-[15px] mt-[2px] max-h-[26px];
  }
  .i-circle-notifications {
    @apply bg-[#49ABA0] pt-[2px] px-[8px] text-white sm:px-[9px] sm:py-[2px] rounded-full sm:text-[12px] sm:max-h-[34px] sm:mt-[2px] sm:pt-[8px] text-sm max-h-[24px] mt-[2px];
  }
                                                   
  
}
