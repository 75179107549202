.chart-container .card-container .quick-win-card {
    top: -200px;
    left: 100px;
}

.chart-container .card-container .gem-win-card {
    top: 10px;
    left: 10px;
}

.chart-container .card-container .avoid-card {
    top: -200px;
    left: 400px;
}

.chart-container .card-container .strategric-card {
    top: 10px;
    left: 200px;
}

.chart-container  .active-card {
    background-color: #799d4b ;
    /* opacity: 0.12; */
    border-radius: 4px;
    font-style:normal !important;
    color: #DEDEDE  !important;
    font-style: normal;
}

.chart-container  .active-avoid-card {
    background-color: #EB1C2C ;
    /* opacity: 0.12; */
    border-radius: 4px;
    font-style:normal !important;
    color: #DEDEDE  !important;
    font-style: normal;
}

.chart-container  .inactive-card {
    color: white  !important;
    background-color: #C2C3C7 !important;
    /* opacity: 0.3; */
    border-radius: 4px;
}

.chart-container .card-container  .active-avoid-card div,
.chart-container .card-container  .active-card div,
.chart-container .card-container  .inactive-card div {
    margin:5px;
}

.chart-container .card-container .active-card .result-card-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
}

.chart-container .card-container .inactive-card .result-card-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
}

.chart-container .card-container .result-card-subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}