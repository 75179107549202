
.gem-confirm-container {
    width: 500px;
}

.gem-confirm-container > div {
    margin-top:20px;
}

.gem-confirm-table-header {
    display: flex;
    flex-direction: row;
}

.gem-confirm-table-header > div {
  padding: 10px;
}

.gem-confirm-table-row {
    display: flex;
    flex-direction: row;
}

.gem-confirm-table-row > div {
    padding: 10px;
  }

.gem-confirm-table-header div {
    width:200px;
    height: 100px;
    background-color: #F3F3F3;
/* Core/Light Grey */

border: 1px solid #DEDEDE;
}

.gem-confirm-table-row div {
    width:200px;
    height: 120px;
    border: 1px solid #DEDEDE;
}

.achievedBenefit-input {
    height: 100px;
    border-style: solid;
    border-width:  1px;
    width: 100%;
    margin-bottom: 40px;
}

.gem-confirm-button {
    margin-left: 10px;
}