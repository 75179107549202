
.quickwins-container {
    width:90%;
    display:flex;
    flex-direction: column;
}

.quickwins-side {
    @apply flex flex-col sm:flex-row
}


.quickwins-container .chain{
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
}

.quickwins-container .quickwins-side .quickwins-left {
    @apply w-[30%] min-w-[350px] sm:min-w-[300px] max-w-[420px] 
}

.quickwins-container .quickwins-side .quickwins-right {
    @apply w-[105%] sm:w-[70%]
}

.quickwins-container .quickwins-side .quickwins-left .team-member-container button.ap-button {
    height: 34px !important;
}

.quickwins-container .quickwins-side .quickwins-left .team-member-container .team-member-save .ap-button-label {
    font-weight: 400;
    font-size:12px;
    line-height: 17px;
}


.quickwins-container .team-member-container {
    width: 95%;
    margin-left: 0px !important;
}

.quickwins-container .team-member-container>div {
    @apply ml-[2px] mt-[10px] mb-[5px] sm:mb-[10px]
}



.quickwins-container .bar {
    font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */
  
  
  /* Neutral/Neutral-17 */
  
  color: #474747;
  }

  .quickwins-container .title{
    @apply font-semibold text-[18px] sm:text-[24px] leading-[28px] text-[#221E1F] mt-[5px] mb-[5px] sm:mt-[20px] sm:mb-[20px]
}

.quickwins-container .header {
    @apply font-semibold text-[18px] sm:text-[20px] leading-[28px] mt-0 sm:mt-[20px] mb-0 sm:mb-[15px] p-0
}

.quickwins-container .subtitle{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    
    /* Core/Grey */
    
    color: #7D7D7D;
    margin-bottom: 11px;
}

.quickwins-container .problem {
    margin-top:11px;
    margin-bottom:20px;
}

.quickwins-container .question {
    font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
/* identical to box height */


/* Pembina black */

color: #221E1F;

}

.quickwins-container .answer{
    width: 60%;
    margin-top: 9px;
}

.quickwins-container .quickwins-submit {
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    
}

.quickwins-container .quickwins-submit button  {
 height: 34px;
    }

    .quickwins-container .quickwins-submit .ap-button-secondary {
        color:#EB1C2C;;
        box-shadow: inset 0 0 0 0.0625rem #EB1C2C;
        margin-left: 10px;
    }



.quickwins-container .quickwins-submit button span {
/* Pembina Red */


font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 17px;
}

.quickwins-container  hr {
    width: 70%;
}

.popup-active {
    opacity: 0.07;
    backdrop-filter: blur(5px);
}

.quickwins-container  .icon-help-question-outline {
     color: #7D7D7D;;
}

.ap-button-loading-icon{
    background-color: red !important;
}

.quickwins-submit .int-item {
    margin-top: 10px;
    margin-left: 25px;
}

.quickwins-container .quickwins-side .switchbox {
    margin-top: 40px;
    margin-left: 80%;
    z-index: 1;
    position: absolute;
    @apply   mt-[40px] ml-[30%] sm:ml-[80%] z-[1] absolute 
}