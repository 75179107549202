
.footer-container {
    @apply flex flex-row flex-wrap pl-[10px] pt-0 pb-[30px] sm:pb-[10px]  relative bottom-[50px] min-w-[250px] sm:pt-[10px] sm:p-[35px]
}

.footer-container * {
font-weight: 400;
font-size: 10px;
line-height: 11px;

/* Core/Grey */

color: #7D7D7D;
}

 .footer-container div {
    @apply mr-[20px] 
} 
