.App {
  display: flex;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ap-tabset-container .ap-tabset-toggle-wrapper.underline .ap-tabset-toggle.active .ap-tabset-toggle-active {
  color: #EB1C2C;
}

.ap-tabset-container .ap-tabset-toggle-wrapper.underline .ap-tabset-toggle.active .ap-tabset-toggle-underline {
  background-color: #EB1C2C;
  height: 0.2rem;
}

.ap-checkbox .icon-checkbox-backdrop-outline .icon-checkbox-selected-fill {
  color: #EB1C2C;
}

/* :root {
  --color-background-primary: #EB1C2C !important;
} */

.MuiDataGrid-columnHeader {
  background-color: #EB1C2C;
  border-right-style: solid;
  border-right-color: #DEDEDE;
  border-right-width: 1px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: -0.4px;

  /* Core/White */

  color: #FFFFFF;
}