.problem-desc {
    margin-bottom: 20px;
}

.comment-modal-wrapper {
    width: 440px;
    min-height: 475px;
}

.comment-modal-wrapper .close-button {
    margin-top: -8px;
    padding-top: 8px;
}