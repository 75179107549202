
.chart-container .chart-wrapper {
    display: inline-block;
    position: relative;
    width: 100% !important;
}


.chart-container .card-container > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    margin: 10px;
    @apply flex flex-col justify-center items-center w-[110px] h-[100px] text-[12px] leading-[12px] m-1 sm:w-[200px] sm:h-[200px] text-[700] sm:text-[16px] sm:leading-[18px] sm:m-[10px]
   
}

.canvas-box {
    position: relative;
    width:550px;
    height:550px;

}

.canvas-box canvas {
    width: 100%;
    height:100%;
}

