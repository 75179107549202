.header-container {
  @apply flex sm:mb-0 sm:mt-0 sm:shadow-black sm:w-[100%]
}

.header {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 60px;
    text-align: left;
    height: 50px;
}

.header-container .dl-header-right {
    display: none !important;
  }

  .header-container .avatar-name-circle {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: #d04a02;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: .8rem;
    border: none;
  }

  .header-container .navbar {
    overflow:hidden;
    min-width: 120px;
  }

 

  .header-container .icon-spacing {
    margin-right: 8px;
    margin-left: 8px;
  }

  .header-container .logoImg {
    @apply flex justify-center items-center sm:justify-start
  }

  .header-container div.logoImg img {
    height: 70% !important;
    margin-right: 10px;
  }

  .header-container div.logoImg span {
    color: #111111;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    letter-spacing: -0.4px;
  }

  .header-container div.logoImg span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

.header-container .notifications-available-dot {
  @apply bg-[#eb1c2c] w-[10px] h-[10px] rounded-full sm:ml-[-20px] sm:mt-[8px] sm:mr-[10px]
}


