.square {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 1px solid;
    background-color: blue;
    position: absolute;
    top: 10px;
    left:10px;
    z-index:99999
}

.square-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}