.ha-container {
    display:flex;
    flex-direction: column;
}

.ha-dragndrop {
    display: flex;
    flex-direction: row;
}

.ha-container .step-title {
    font-weight: 700;
font-size: 16px;
line-height: 18px;

/* Pembina black */

color: #221E1F;
margin-bottom: 10px;
}

.ha-container .step-subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    
    
    /* Pembina black */
    
    color: #221E1F;
    margin-bottom: 10px;
}


.ha-dragndrop .ha-container .ha-draggable {
   width:40%;
}

.ha-container .ha-draggable  .drag-instruction {
    display: flex;
    flex-direction: row;
    padding: 5px;
    background: #F3F3F3;
    border-radius: 4px;
    width: 70%;
    
}

.ha-container .ha-draggable  .drag-instruction .drag-instructino-content{
    margin-left: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    
    /* Pembina black */
    
    color: #221E1F;
}

.ha-dragndrop .ha-container .ha-droppable {
    width:60%

}

.ha-droppable .chart-container {
    height: 600px;
}

