.step-questions-container {
    display: flex;
    flex-direction: column;
    width:90%;
}

.step-questions-container  .step-title{
    font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 18px;

/* Pembina black */

color: #221E1F;
margin-bottom: 10px;
}

.step-questions-container  .group-add {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.step-questions-container  .group-add * {
    color: #EB1C2C;;
}