.stepper-container{
    width:100%;
}

/* .stepper-container .MuiStepIcon-root.MuiStepIcon-active {
    color: #EB1C2C;
} */


.stepper-container  .MuiStepIcon-root.MuiStepIcon-completed {
    color: #EB1C2C;
}

.stepper-container .MuiStepIcon-root.MuiStepIcon-active {

    color: red;
}