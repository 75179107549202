.question-edittext-container {
  display: flex;
  flex-direction: column;
}

.question-edittext-container .question {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #221E1F;
}

.question-edittext-container .title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.question-edittext-container .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  margin-bottom: 25px;
  margin-top: 10px;
}

.question-edittext-container .subtitle {
  display: flex;
  flex-direction: row;
  background: #F3F3F3;
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 8px;

}

.question-edittext-container .subtitle .subtitle-content {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  /* Pembina black */

  color: #221E1F;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.question-edittext-container .content .edittext {
  height: 40px;
}

.question-edittext-container .content .edittext input {
  /* width: 420px; */
  height: 100%;
  width: 100%;

  /* Core/White */

  background: #FFFFFF;
  /* Core/Light Grey */

  border: 1px solid #DEDEDE;
  border-radius: 4px;
  padding-left: 5px;

  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #221E1F;

}

.question-edittext-container .question a {
  text-decoration: underline;
  color: #221E1F;
}