.hypothesis-modal-container {
    display: flex;
}

.hypothesis-modal-header {
    padding-left:10px;
    font-weight: 500;
font-size: 20px;
line-height: 24px;
/* identical to box height, or 120% */

letter-spacing: -0.4px;

/* Text/Heading */

color: #252525;
}

.information-container {
    display: flex;
    flex-direction: row;
    width:600px;
}

 .information-container .left{
    display: flex;
    flex-direction: column;
    width:50%;
    margin-left: 10px;

}

 .information-container .right{
    display: flex;
    flex-direction: column;
    width:50%;
    margin-left:10px;

}

.information-container .left  .header
{
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    
    
    /* Primary/Blue/Blue-04 */
    
    color: #415385;
    padding-left: 0px;
}

.information-container .right  .header
{
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    
    
    /* Primary/Teal/Teal-05 */
    
    color: #299D8F;
    padding-left: 0px;
}

.information-container .left  .title{
    background: #415385;
border-radius: 4px;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
/* identical to box height */


/* Core/White */

color: #FFFFFF;
margin-top: 10px;
padding-left: 10px;

}
.information-container .right  .title
{
    background: #49ABA0;
border-radius: 4px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    
    
    /* Core/White */
    
    color: #FFFFFF;
    margin-top: 10px;
    padding-left: 10px;
}

.information-container .left  .content,
.information-container .right  .content {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    
    /* Core/Darker Grey */
    
    color: #111111;
    padding-top:5px;
    padding-bottom: 10px;
}
