
.question-container {
    display:flex;
    flex-direction: column;
    font-style:normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
 }

 
 .question-container  .additional-question .additional-question-title {
   margin-top: 5px;
   margin-bottom: 10px;
 }

 .question-container  .question-title a {
   color: #221E1F;
   text-decoration: underline;
 }
 
 .question-buttons div span {
     color:  #EB1C2C;
 }
 
 .question-buttons {
     margin-top: 10px;
     display: flex;
     flex-direction: row;
     justify-content: space-between;
 }
 
 .question-container .ap-button.ap-button-secondary {
     color: #221E1F;
 background-color: transparent;
 box-shadow: inset 0 0 0 0.0625rem #EB1C2C;
 width: 48%;
 }

 .question-container .additional-question {
    margin-top: 10px;
 }

 .question-container .button-selected {
   background-color: #EB1C2C !important;
   color: #FFFFFF !important;
}

.question-container .button-unselected {
    background-color: #FFFFFF !important;
    color: #221E1F !important;
 }

 .question-container .red-star {
    color:red;
    margin-left: 2px;
 }

 .question-container div.question-subtitle {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    margin-top:5px;
 }

 .question-container div.question-subtitle div {
    width: 48% !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #7D7D7D;
    margin-top:2px;
 }

 /* Dropdown text */
 .question-container .ap-field .ap-field-container .ap-field-wrapper input {
   margin: 0px;
   padding-left: 5px;
  }

  .question-container .additional-question-subtitle  div {
     width: 90% !important;
     font-style: normal;
     font-weight: 400;
     font-size: 14px;
     line-height: 16px;
     color: #7D7D7D;
     margin-bottom: 10px;
  }

 


 