.question-view-container {
    display: flex;
    flex-direction: column;

    margin-top: 50px;
    width: 100%;
    padding-left: 30px;    

}

.question-view-container .title{
    font-weight: 500;
font-size: 18px;
line-height: 22px;
/* identical to box height */


/* Pembina black */

color: #221E1F;
}

.question-view-container .question-container{
    margin-bottom: 30px;

}

.question-view-container .question-container .question-title {

    font-weight: 700;
font-size: 14px;
line-height: 17px;
/* identical to box height */


/* Pembina black */

color: #221E1F;
}

.question-view-container .question-container .answer {
    font-weight: 400;
font-size: 14px;
line-height: 17px;
/* identical to box height */


/* Pembina black */

color: #221E1F;
margin-top: 8px;
}