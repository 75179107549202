.request-card-container{
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    /* Core/Light Grey */
    
    border: 1px solid #DEDEDE;
    border-radius: 8px;

    width:600px;
    margin-top: 10px;
    padding:20px;
}

.request-card-container  .problem-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 5px;
    margin-bottom: 5px;
}

.request-card-container  .problem-title > a {
    color: #E45C2B;
}

.request-card-container .pending-status-badge  {
    margin-right: 20px;
}

.request-card-container .pending-status-badge .ap-badge  {
    background: #E45C2B;
    /* border-radius: 16px; */
    color: white;
    min-width: 100px;
}

.request-card-container .request-message {
    background: #F3F3F3;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    
    /* Pembina black */
    
    color: #221E1F;    
    min-height: 50px;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}


.request-status {
    display: flex;
    justify-content:right;
    align-items: center;
    margin-left: auto;
}

.request-status .Approved .ap-badge.ap-badge-primary{
    background-color: #415385;;
}

.request-status .Declined .ap-badge.ap-badge-primary {
    background-color: #C52A1A;;
}

.request-status .Solved .ap-badge.ap-badge-primary{
    background-color: #21812D;;
}

.request-status .Pending .ap-badge.ap-badge-primary {
    background-color: #E45C2B;
}

.request-status .Progress .ap-badge.ap-badge-primary {
    background-color: #FFBF1F;;
}

.request-filter .ap-checkbox .icon-checkbox-backdrop-outline .icon-checkbox-selected-fill{
    color: #EB1C2C;
}

.request-card-container .user{
    display: flex;
    justify-content: center;
    align-items: center;

}

.request-card-container .user .i-circle {
    width: 30px;
    height: 30px;
    max-height: 30px;
    /* Status/Success/Success-05 */
    
    border-radius: 40px; 
    display: flex;
    justify-content: center;
    align-items: center;
}

