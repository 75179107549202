.training-topic-container {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.training-topic-container .training-topic-description {
  min-width: 200px;
}

.training-topic-container .training-topic-button {
  @apply ml-0 flex  sm:ml-auto sm:flex-row-reverse
}


.training-topic-container .training-topic-description .training-topic-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;

  color: #EB1C2C;
}

.training-topic-container .training-topic-description .training-topic-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #7D7D7D;
}

.training-topic-container .training-topic-title-container {
  @apply flex  flex-col sm:flex-row sm:items-center sm:justify-start
}

.training-topic-container .training-completed-flag {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: #21812D;
  background: #D3EBD5;
  border-radius: 16px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: 10px;
}

.training-topic-container .training-mandatory-flag {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: black;
  background: pink;
  border-radius: 16px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: 10px;
}



.training-topic-container .training-topic-button a {
  @apply bg-[#EB1C2C] text-white font-normal py-2 px-4 rounded mt-2 text-[12px] max-h-[34px]

}

.training-topic-container .logo-container div {
  margin-bottom: 20px;
  margin-top: 10px;
  margin-right: 20px;
}

.logo-container {
  @apply flex flex-col justify-center items-center min-w-[50px] max-w-[50px] sm:min-w-[120px] sm:max-w-[120px] mr-[20px]

}

.logo-container img {
  min-width: 100%;
  max-width: 100%;
  object-fit: cover;
}