.tag-user-component {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.tag-user-list {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
}

.tag-user-component .ap-combobox-multiple {
  min-width: 300px !important;
}

.tag-user-list .ap-tag {
    background-color: red !important;
    border-radius: 1rem;
}