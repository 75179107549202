.hypothesis-grid-container {
    width:90%;
    min-width: 1200px;
    /* margin-right: 120px; */
}

.hypothesis-grid-container  .step-title{
    font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 18px;

/* Pembina black */

color: #221E1F;
margin-bottom: 10px;

}

.hypothesis-grid-container .group-add {
    margin-top: 10px;
    margin-bottom: 10px;
}


.hypothesis-grid-container .group-add span {
    color: #EB1C2C;
} 


.hypothesis-info-table {
    display: flex;
    flex-direction: column;
    width:100%;
    margin-top: 10px ;
    margin-bottom: 10px;
}

.hypothesis-info-table > div {
    min-height: 50px;
}

.hypothesis-info-table .hypothesis-info-table-statement-header{
    display: flex;
    background: #EB1C2C;
/* Core/Light Grey */

 border: 1px solid #DEDEDE; 

border-radius: 8px 8px 0px 0px;
color: #FFFFFF;
align-items: center;
padding-left: 10px;

}

.hypothesis-info-table .hypothesis-info-table-statement{
    display: flex;
    border: 1px solid black;
    height: 300px;
    align-items: center;
    padding-left: 10px;

}

.hypothesis-info-table .hypothesis-info-table-columns-header div{
    border: 1px solid black;
    width: 20%;
    display: flex;
    align-items: center;
    padding-left: 10px;
}


.hypothesis-info-table .hypothesis-info-table-columns{
    display: flex;
    flex-direction: row;
    border: 1px solid black;
    min-height: 50px;

}

.hypothesis-info-table .hypothesis-info-table-columns div{
    border: 1px solid black;
    width: 20%;
    font-weight: 400;
font-size: 14px;
line-height: 17px;

/* Pembina black */

color: #221E1F;
border: 1px solid #DEDEDE;

display: flex;
align-items: center;
padding-left: 10px;

}

.hypothesis-info-table .hypothesis-info-table-columns-header{
    display: flex;
    flex-direction: row;
    background: #EB1C2C;
/* Core/Light Grey */
border: 1px solid #DEDEDE;
font-weight: 500;
font-size: 14px;
line-height: 17px;
/* identical to box height */


/* Core/White */

color: #FFFFFF;
}

.hypothesis-info-table-statement textarea{
    width:100%;
    height:100%;
}