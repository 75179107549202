.strategic-container {
    display: flex;
    flex: row;
}

.strategic-navigation-container {
    width: 30%;
    min-width: 200px !important;
}

.strategic-navigation-container .title {
    margin-left: 30px;
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;

    color: #221E1F;

}

.strategic-navigation-container .subtitle {
    margin-left: 30px;
    margin-top: 5px;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;

    color: #7D7D7D;
}

.strategic-step-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.strategic-step-container .button-container {
    display: flex;
    flex:row;
    margin-bottom: 20px;
}

.strategic-step-container .button-container button {
    margin-right: 10px;
}

.question-red-star {
    color: #EB1C2C;;
}

.strategic-step-container .button-container .ap-button:not([disabled]).ap-button-secondary {
    color: #EB1C2C;;
    background-color: transparent;
    box-shadow: inset 0 0 0 .0625rem #EB1C2C;;
}


.strategic-step-container .button-container .ap-button:not([disabled]).ap-button-secondary:not(.ap-button-loading):focus:not(.keyboard-focus) {
    color: #EB1C2C;;
    background-color: transparent;
    box-shadow: inset 0 0 0 .0625rem #EB1C2C;
}

.strategic-container   .icon-help-question-outline {
    color: #7D7D7D;;
}


.strategic-container .ap-button.ap-button-secondary .ap-button-loading-icon, .ap-button.ap-button-text .ap-button-loading-icon{
    background-color: #EB1C2C;
}

.strategic-container .button-container .int-item {
    margin-top: 15px;
    margin-left: 20px;
}

.strategic-container .button-container .int-item .checkbox {
    display: inline-block;
}

.strategic-step-container .switchbox {
    margin-left: 60%;
    z-index: 1;
    position: absolute;
    display: inline-block;
}