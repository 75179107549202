

 .nav ul li {
    @apply  w-[100%] sm:h-[70px] h-min p-[2px]
}

.nav ul.no-bullets {
    list-style-type: none;
    justify-content: center;
}

.nav ul li a {
    @apply bg-transparent text-[#7D7D7D] sm:h-[100%] justify-center items-center flex h-[70px] text-[12px]
}

.nav ul li a div {
    @apply sm:h-[100%] sm:flex sm:flex-col sm:items-center sm:justify-center sm:pt-[20px] sm:pb-[20px]
}

.nav ul li span {
    padding-top: 15px;
}

.nav ul li a:hover {
       color: Core/Grey;
}

.nav ul li a:hover {
    background-color: #d56363 !important;
    color: #FFFFFF;
}

.nav ul li.active-tab a {
    background-color: #D83737 !important;
    color: #FFFFFF;
}

.nav ul li.active-tab a:hover {
    background-color: #8d2727 !important;
    color: #FFFFFF;
}



.nav ul li a:hover {
       color: Core/Grey;
}
.nav {
      @apply fixed z-10 bottom-0 w-full sm:text-xs sm:relative sm:z-0 sm:flex sm:flex-wrap sm:mb-0 sm:pl-0 sm:w-[79px] sm:max-h-none sm:bg-[#E5E5E5]
} 
.navbar {
    justify-content: flex-end;
}