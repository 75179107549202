.profile_container {
     display: flex; 
     flex-direction: column;
}

 .profile_container .profile-input-short {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
} 


.profile-flex-parent {
    display: flex;

}

.profile-save {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
}

.profile-flex-parent > * {
    flex: 1;
}

.profile_form {
    overflow-y: scroll; 
    height: 400px;
}


.profile-input-left {
    display: flex;
    flex-direction: column;
   margin-right: 8px;
}

.profile-input-right {
    display: flex;
    flex-direction: column;    
  margin-left: 8px;
}

.profile_container  .profile-label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-top: 20px;
    margin-bottom: 8px;
    height: 18px;
}

.profile-input-short {
    border-style: solid;
    border-width: 1px;
    border-color: #DEDEDE;
    border-radius: 4px;
    height: 34px;
    padding: 8.5px 10px;
    gap: 10px;
}

.profile-input-long {
    border-style: solid;
    border-width: 1px;
    border-color: #DEDEDE;
    border-radius: 4px;
    height: 34px;
    padding: 8.5px 10px;
    gap: 10px;
}


.profile-input-control {
    display: flex;
    flex-direction: column;
}

.profile-input-control .profile-select-desc {
    color: #7D7D7D !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}


.profile_container .ap-dropdown-select .ap-field-wrapper {
    height:34px !important
 }

 .profile_container .ap-field .ap-field-container .ap-field-wrapper input {
   margin:0px;
   padding-left: 5px;
   padding-bottom: 0px;
   padding-top: 0px;
 }

/* change checkbox color */
.profile_container .ap-checkbox .icon-checkbox-backdrop-outline .icon-checkbox-selected-fill{
    color: #EB1C2C;
}

.profile_container .ap-option-item {
    height: 2rem;
  }

  .profile_container .ap-option-label,
  .profile_container .ap-option-item.active .ap-option-label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.profile_container .ap-field .ap-field-container .ap-field-wrapper input {
    margin: 0px;
    padding-left: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
   }

   .profile-button{
    margin-left: 10px;

   }