.reports-container {
    @apply flex flex-col w-[80%] min-h-[550px]
}

.reports-container .title {
    @apply mt-0 mb-1 sm:mt-[20px] sm:mb-[20px] font-semibold text-[20px] sm:text-[24px] leading-[29px] 
}

.report-filter {
    @apply flex flex-col sm:flex-row justify-start mt-[20px] mb-[20px] gap-1
}

.report-filter > div {
    margin-right: 20px;
}


.report-charts-container {
    display: flex;
    flex-direction: column;
}

.report-chart {
    display: flex;
    flex-direction: column;
}

.report-chart-container {
    display: flex;
    flex-direction: column;
    width:90%;
    
    min-height: 300px !important;
    padding: 20px;
}

.report-problem-stackedbar {
    height: 450px !important;
}


.report-grid {
    margin-top: 20px;
    margin-bottom: 20px;
}

.report-chart-title {
    @apply mt-[20px] mb-[5px] font-semibold text-[16px] leading-[24px] ml-[75px] sm:ml-0  sm:max-w-none
}

.report-filter-size-one {
    width: 300px !important;
    margin-bottom: 10px;
}

.report-charts-container-responsive {
    @apply flex flex-col justify-center items-center sm:ml-0
}

@media only screen and (min-width: 1200px) {
    .report-charts-container-responsive {
        display: flex;
        flex-direction: row;
    }
}



