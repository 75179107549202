.help-container{
    display: flex;
    flex-direction: column;
}

.help-section {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.help-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
   
    
    /* Pembina black */
    
    color: #221E1F;
    margin-bottom: 5px;
}

.help-title a {
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    
    /* Pembina black */
    
    color: #221E1F;
    margin-bottom: 5px;
    border: none;
}

.help-title a:focus-visible,
.help-content a:focus-visible {
    outline: none;
}

.help-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    
    
    /* Pembina black */
    
    color: #221E1F;
    margin-top:10px;
    margin-left: 5px;
}

.help-header-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    
    /* Pembina black */
    
    color: #221E1F;
    margin-top: 20px;
    margin-left: 20px; 
    margin-bottom: 10px;
}

.help-modal-button {
    margin-top:20px;
    margin-bottom: 20px;
    margin-right:20px;
}