.step-group-questions-container {
    display: flex;
    flex-direction: column;
    width:100%;
}

.step-group-questions-container  .group-add {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.step-group-questions-container  .group-add * {
    color: #EB1C2C;
}

.step-group-questions-container .remove-bin {
    color: #EB1C2C;
    position: relative;
    float: right;
}

.step-group-questions-container .remove-bin span {
    color: #EB1C2C;
} 

/* change checkbox color */
.step-group-questions-container .ap-checkbox .icon-checkbox-backdrop-outline .icon-checkbox-selected-fill{
    color: #EB1C2C;
}

.step-group-questions-container .ap-option-item.active {
  background-color: transparent;
}

.step-group-questions-container  .ap-option-label,
.step-group-questions-container .ap-option-item.active .ap-option-label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.step-group-questions-container .ap-option-item {
  height: 2rem;
}

/* Dropdown text */
.step-group-questions-container .ap-field .ap-field-container .ap-field-wrapper input,
.step-group-questions-container label.ap-pagination-field {
 margin: 0px;
 padding-left: 5px;
 padding-right: 5px;
 font-style: normal;
 font-weight: 400;
 font-size: 12px;
 line-height: 14px;
}