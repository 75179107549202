
.problem-card {
    @apply flex flex-col sm:mt-5 p-3 sm:p-2.5 text-xs w-[100%] gap-[3px] sm:mt-0
}


.problem-card  .problem-description {
    color: #221E1F;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 5px;
}

.problem-card  .problem-title .ap-button{
    color: #EB1C2C;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    padding-left:0px;
}

.problem-card  .problem-title .ap-button:hover {
    background-color: transparent !important;
}
.time-label{
    font-weight:400;
    opacity: 60%;
}
.dept-label{
    font-weight: 400;
    color:#415385;
}
.status-badge{
    @apply ml-1 sm:ml-2.5 flex flex-col gap-[2px] sm:flex-row
}
.problem-title{
    @apply flex gap-0.5 justify-end 
}

.problem-title > a {
@apply font-[700] text-sm sm:text-[11px] text-[#EB1C2C]
}

.request-card-container .problem-description {
    font-weight: 400;
font-size: 11px;
line-height: 17px;

/* Pembina black */

color: #221E1F;
}

.user-info{
    display:flex;
    flex-direction: column;
    font-size: small;
}
.user{
    display: flex;
    gap:10px;
}

.bucket-start {
    @apply mr-0 pr-0.5 break-words text-[#eb1c2c] font-bold cursor-pointer sm:pr-2.5 w-[90%]
  }
  .comments-likes{
    display: flex;
  }
.intraction-section{
    display: flex;
}
.intraction-section .int-item{
    margin-top: 3px;
    margin-left: 1%;
    margin-right: 4%;
    margin-bottom: 3px;
    font-weight: 500;
    font-size: small;
    cursor: pointer;
    color: #474444;
}
.Appkit4-icon{
    margin-bottom: 3px;
    cursor: pointer;
}