.chart-container {
    @apply w-[100%] ml-[0px] sm:ml-[20px] max-h-[350px] sm:max-h-none
}

.chart-container .chart-wrapper {
    display: inline-block;
    position: relative;
    width: 100% !important;
}


.chart-container .card-container {
    @apply relative flex flex-wrap h-[250px] w-[250px] top-[-300px] sm:h-[450px] sm:w-[450px]  sm:top-[-510px] left-[60px] min-w-[250px] sm:min-w-[450px] 
    
}

.chart-container .card-container > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    margin: 10px;
   
}

.canvas-box {
    position: relative;
    width:550px;
    height:550px;

}

.canvas-box canvas {
    width: 100%;
    height:100%;
}

.square-container {
    width: 100%;
    height: 100%;
    position: relative;
}


.card-container .gem-card {
    background: #415385;
    border-radius: 4px;
}

.card-container .quick-win-card  {
    background: #49ABA0;
border-radius: 4px;
}

.card-container .strategic-card {
    background: #E27588;
    border-radius: 4px;
}

.card-container .avoid-card{
    background: #E0301E;
    border-radius: 4px;
}
