.tool-bar {
  display: flex;
  flex-direction: row-reverse;
}

/* .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  } */

.tool-bar .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: transparent;
  color: grey;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-weight: 500;
  font-size: 12px;
  position: absolute;
  z-index: 1;
}

.quickwins-problem-container .question {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #221E1F;
}

.quickwins-problem-container .question .group {
  font-style: normal;
  font-weight: 525;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 5px;
  color: #221E1F;
}

.image-upload:hover .tooltiptext,
.video-upload:hover .tooltiptext {
  visibility: visible;
}

.image-upload>label>input {
  visibility: hidden;
  width: 0;
  height: 0;
}

.video-upload>label>input {
  visibility: hidden;
  width: 0;
  height: 0;
}


.quickwins-problem-container .title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.quickwins-problem-container .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  margin-bottom: 25px;
}

.quickwins-problem-container textarea {
  width: 100%;
  min-height: 200px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* or 150% */

  /* Pembina black */

  color: #221E1F;

}

.quickwins-problem-container textarea:focus {
  outline: none;
}

.quickwins-problem-container .ap-tooltip-text{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.quickwins-problem-container .question-red-star {
  color: #EB1C2C;;
}
.quickwins-problem-container a{
  color: black !important;
  text-decoration: underline;
}