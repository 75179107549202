
.slideshow-outer-container{
  display: flex;
  flex-direction: column;
}

.pagination-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.slideshow-container{
    width:100%;
    height:100%;
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;
}

.slideshow-container .slide {
    display: flex;
    overflow: hidden;
    width:100%;
    height: 100%;
    justify-content: center !important;
}

.slideshow-container .slide  img,
.slideshow-container .slide  video{
    flex-shrink: 0;
     object-fit: contain; 
    width:100%;
    height: 300px;
    /* object-position: center; */
    margin: auto;
}

.slideshow-container .prev,
.slideshow-container .next {
    cursor: pointer;
    position: absolute;
    top: 20%;
    width: auto;
    padding: 16px;
    font-weight: bold;
    transition: 0.8s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

.slideshow-container .prev {
  left:0;
}

.slideshow-container .next {
    right:0;
    border-radius: 3px 0 0 3px;

}

.slideshow-container .delete {
    cursor: pointer;
    position: absolute;
    top: 1%;
    width: auto;
    padding: 16px;
    font-weight: bold;
    transition: 0.8s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    right:0;
    border-radius: 3px 0 0 3px;

}

.slideshow-container .silde-number {
    position: absolute !important;
    top: -20px !important;
    left:40px !important;

}


