
  .draggable-container {
    width: 70%;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-top:10px;
    margin-left: 10px;
    /* margin: 0 auto; */
    border-style: solid;
    border-width: 1px;
    font-style: normal;
    overflow-wrap: break-word;
font-weight: 500;
font-size: 14px;
line-height: 17px;
color: #221E1F;
padding: 10px;
  }