.comments{
    margin-left:10px;
    font-size:13px;
}
.add-comment{
    cursor: pointer;
    font-size: smaller;
}
.ap-field .ap-field-container .ap-field-wrapper {
    height: 2rem;
}
.comments-wrapper .comments .comments-detail .comments-likes .comments-action + .comments-action {
    display: none;
}
.comments-detail{
    @apply max-w-[70%] sm:max-w-none
}
.comment-section{
    display: flex;
    flex-direction: column;
}
.comment-section .Appkit4-icon{
    margin-top:10px;
}
.comment-section Button{
    max-width: 120px;
    border:none;
}
.comment-section .comment-list {
    height: 250px;
    overflow-y: scroll;
}
.add-comment{
    color:white;
    background-color: #4285F4;
    max-width: 25%;
}
.add-comment-button{
    margin-top: 20px;
    margin-left:5px;
}
.ap-button:not([disabled]).ap-button-primary {
    color: var(--color-text-tertiary);
    background-color: #c52a1a !important;
}

.ap-button:not([enabled]).ap-button-primary {
    background-color: #fcbab5 ;
}

.load-more-button-comments{
    color: #c52a1a;
    cursor:pointer;
    font-size: 16px;
    height: 20px;
    padding-top: 2px;
    margin-left:37.5%;
} 