.question-emojipicker-container {
    padding-bottom: 30px;
}

.question-emojipicker-container .title {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    
    
    /* Pembina black */
    
    color: #221E1F;  
}