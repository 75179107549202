
  .dragsquare_container {
    width: 90%;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-top:10px;
    margin-left: 10px;
    /* margin: 0 auto; */
    border-style: solid;
    border-width: 1px;
    font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
color: #221E1F;
padding: 10px;
  }

  .square {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 1px solid;
    background-color: blue;
    position: absolute;
    top: 10px;
    left:10px;
    z-index:99999
}