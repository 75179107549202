
.strategic-confirm-container {
    width: 500px;
}

.strategic-confirm-container  {
    margin-top:20px;
    margin-bottom: 20px;
}

.strategic-confirm-button {
    margin: 10px;
}