.step-grid-container {
    width:90%;
}

.step-grid-container  .step-title{
    font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 18px;

/* Pembina black */

color: #221E1F;
margin-bottom: 10px;

}

.step-grid-container  .step-subtitle{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    
    
    /* Pembina black */
    
    color: #221E1F;

    margin-bottom:10px;

}

.step-grid-container .group-add {
    margin-top: 10px;
    margin-bottom: 10px;
}


.step-grid-container .group-add span {
    color: #EB1C2C;
} 