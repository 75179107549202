.dashboard-container {
    @apply flex flex-col  h-[90%] sm:h-[80%]
}


.dashboard-welcome {
    @apply font-normal  sm:text-[24px] sm:leading-7  text-[18px] leading-4 mb-5
}

.dashboard-container .dashboard-content {
    @apply flex flex-col  sm:flex-row
}

.dashboard-container .problem-category {
    display: flex;
    flex-direction: column;
    min-width: 280px;
    width:30%;
    min-height: 90%;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    border-color: #7D7D7D;
    margin-right: 30px;
    margin-bottom: 10px;
    min-height: 300px;
}
.prob-cat{
    @apply flex-col sm:min-w-[280px]  sm:w-[30%]  border-solid border-[1px] rounded-[4px] border-[#7D7D7D] mr-[30px] mb-[10px] sm:mb-[10px] sm:min-h-[300px]
}

.dashboard-container .problem-category-title {
    background-color: #F3F3F3;
    border-radius: 4px;
    padding-left: 5px;
    height: 42px;

    vertical-align:middle;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 42px;
}

.preferences-homepage{
    margin-top:200px;
    margin-left: 200px;
    opacity: 60%;
    font-size: 25px;
    cursor: pointer;
}
.filter{
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    margin-top:5px;

}
.filter .preferences{
    flex:0.1;
}
.filter .dropdown-list{
    flex:1;

}
.ap-field .ap-field-container .ap-field-wrapper {
    height: 2.75rem;
}
.loader{
    margin:auto;
}
.dashboard-container .problem-category-small {
    display: flex;
    flex-direction: column;
    min-width: 260px;
    width:25%;
    max-height: 110px;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    border-color: #f73b3b;
    margin-right: 30px;
    padding:10px;
    margin-bottom: 10px; 
    
}
.action-button{
    max-width:120px;
    margin-top:10px;
}