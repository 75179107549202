.question-datepicker-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
}

.question-datepicker-container .title {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    /* Pembina black */

    color: #221E1F;
}

.question-datepicker-container .question {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #221E1F;
}


/* .question-datepicker-container .datepicker .a-date-picker-header  {
    width:100%;
} */
.question-datepicker-container .ap-field .ap-field-container .ap-field-wrapper input {
    margin: 0;
    padding-left: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.question-datepicker-container .subtitle {
    display: flex;
    flex-direction: row;
    background: #F3F3F3;
    border-radius: 4px;
    margin-top: 8px;
    margin-bottom: 8px;

}

.question-datepicker-container .subtitle .subtitle-content {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    /* Pembina black */

    color: #221E1F;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}