
.result-chart-container {
    display: flex;
    flex-direction: column;
    max-width: 80%;
}

.result-chart-container .title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start;
}

.result-chart-container .title div {
    @apply font-normal text-[14px] sm:text-[18px] leading-[21px] ml-[10px] mt-[10px] 
}
.result-chart-container  .content .action-button {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  height: 34px;
  width: 96px;
}

.result-chart-container  .content .strategic .action-button {

    width: 128px;
  }

.result-chart-container .content {
    @apply flex flex-col-reverse w-max sm:flex-row
}

.result-chart-container  .result-red {
    @apply text-[#EB1C2C] text-[18px] sm:text-[24px] leading-[20px] ml-[5px] font-semibold
}

.result-chart-container .content .description{
    @apply flex flex-col items-start justify-start w-[100%] text-[400] text-[14px] leading-[18px] mt-[10px] sm:w-[30%] max-w-[330px] sm:max-w-none
}

.result-chart-container .content .description .paragraph{
  margin: 10px;

}

.result-chart-container .content .description .paragraph a{

text-decoration: underline;
color: #221E1F;
}

.result-chart-container .content .chart {
    @apply flex max-w-[350px] w-[60%] sm:min-w-[400px] sm:max-w-none
}

