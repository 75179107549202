.rating-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 150px;
    margin-bottom: 10px;
}

.rating-container .rating-name {
    display: flex;
    width: 30%;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    border: 1px solid #DEDEDE;
    justify-content: center;
    align-items: center;

    border: 1px solid #DEDEDE;
    border-radius: 8px 0px 0px 8px;
}

.rating-container .rating-background-color-1 {
    background-color: #D4EBE9;
    ;
}

.rating-container .rating-background-color-2 {
    background-color: #F9D6D2;
}

.rating-container .rating-content {
    display: flex;
    flex-direction: column;
    width: 40%;
    background: #FFFFFF;

    border: 1px solid #DEDEDE;
    padding-bottom: 2px !important;
}

.rating-container .rating-rationale {
    display: flex;
    width: 30%;
}

.rating-container .rating-rationale>textarea {
    width: 100%;
    height: 100%;
    border: 1px solid #DEDEDE;
    border-radius: 0px 8px 8px 0px;
}

.rating-container .rating-description {
    margin: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.rating-container .rating-radios {
    margin: 10px;
    display: flex;
    flex-direction: row;
}

.rating-container .rating-radios .radio-left {
    width: 15%;
    font-size: 12px;
    line-height: 14px;

    /* Pembina black */

    color: #221E1F;
    margin-top: 20px;
}

.rating-container .rating-radios .radio-right {
    width: 15%;
    font-size: 12px;
    line-height: 14px;

    /* Pembina black */

    color: #221E1F;
    margin-top: 20px;
}

.rating-container .rating-radios .radio-middle {
    width: 70%;
}

.rating-container .rating-radios .radio-middle>div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rating-container .rating-radios .ap-ratings .ap-ratings-item {
    border-radius: 20px;
    height: 1rem;
    width: 1rem;
    margin-left: 5px;
    margin-right: 5px;
}

.rating-comment {
    width: 100%
}

.rating-comment textarea {
    width: 100%;
    height: 100%;
    border: 1px solid #DEDEDE;
    border-radius: 0px;
}

.radio-middle {
    min-width: 140px;
}

.radio-middle-label {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    margin-bottom: 5px;
}

.radio-middle .ap-ratings {
    gap: 10px;
}

.radio-middle-label>div {
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    /* Pembina black */

    color: #221E1F;
    width: 1rem;
    height: 1rem;
}