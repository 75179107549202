
.welcome-container {
    width: 50%
}

@media only screen and (max-width: 600px) {
    .welcome-container {
        width: 100%
    }
}

.welcome-title {
    font-weight: 400;
    font-size: 24px;
    line-height: 28.63px;
    margin-top:0px;
    margin-bottom: 24px;
}

.welcome-content {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px; 
}

.welcome-image {
    margin-top: 17px;
    margin-bottom: 25px;
    min-height:400px;
}

.welcome-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.welcome-container .welcome-button {
    margin-top: 50px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px; 
}

.welcome-button button {
    width:100px;
    height: 34px;
    background-color: #D83737;
    border-radius: 4px;
    color: #ffffff;
}