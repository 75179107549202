.dl-header-right {
    display: none !important;
}

main {
    @apply pt-[10px] sm:pt-[30px] sm:pl-[35px] pl-[10px] float-left w-[100%] overflow-x-hidden break-normal h-fit overflow-y-hidden  relative pb-[50px]
}

div.layout {
    width: 100%
}

.navigationWrapper {
    /* height: 100%; */
    display: flex;
}

.icon-spacing {
    margin-left: 16px;
    margin-right: 16px;
}