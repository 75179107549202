.leaderboard-filter-container {
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    margin-right: 50px;
}

.leaderboard-filter-container > div{
    margin-right: 10px;
    
}

