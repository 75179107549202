.register-container {
    @apply min-w-[200px] max-w-[98%] sm:pb-[10px] sm:min-w-[400px] sm:max-w-[40%] pb-0
}


.register-container .register-bar {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
}

.register-container .register-title {
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    display: inline-block;
}

.register-container div label {
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}


.register-container div label span {
    color: red;
}

.register-container .ap-field-wrapper {
    height: 25px !important;
}

.register-container .textarea .ap-field-wrapper {
    height: 206px !important;
}

.register-container .ap-field-wrapper .ap-field-input {
    color: #111111 !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px 0 5px !important;
    padding: 0 !important;
}

.register-container .ap-field-wrapper textarea .ap-field-input {
    height: 50px;
    color: #111111 !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px 0 5px !important;
    padding: 0 !important;
}

.register-container .register-button {
    width: 91px !important;
    height: 34px !important;
    border-radius: 4px;

    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.register-container .register-button * {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
}


.register-container .ap-field-input .ap-field-suffix-wrapper {
    height: 100% !important;
}

.register-container .a-date-picker-header {
    height: 100% !important;
    font-size: 11px !important;
}

.register-container .react-calender {
    font-size: 11px !important;
}


.register-container textarea {
    border-style: solid;
    border-width: 1px;
    border-color: #DEDEDE;
    border-radius: 4px;
    height: 104px;
    padding: 8.5px 10px;
    gap: 10px;
    width: 100%
}

.register-input {
    border-style: solid;
    border-width: 1px;
    border-color: #DEDEDE;
    border-radius: 4px;
    height: 34px;
    padding: 8.5px 10px;
    gap: 10px;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    resize: none;
}

.register-container .ap-dropdown-select .ap-field-wrapper {
    height: 34px !important
}

.register-container .a-calendar .ap-field-wrapper {
    height: 34px !important
}


.register-container .a-calendar .ap-field-suffix-wrapper {
    height: 100% !important;
}

.register-container .a-calendar .a-date-picker-header {
    width: 100% !important;
}

.register-container .a-calendar .react-date-picker {
    width: 100% !important;
}

.register-container .a-calendar .ap-field-suffix-wrapper span {
    margin-left: auto !important;
}

.register-container .ap-checkbox .icon-checkbox-backdrop-outline .icon-checkbox-selected-fill {
    color: #EB1C2C;
}

.register-container .ap-option-item.active {
    background-color: transparent;
}

.register-container .ap-option-label,
.register-container .ap-option-item.active .ap-option-label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.register-container .ap-option-item {
    height: 2rem;
}

.register-container .register-component {
    margin-top: 10px;
    margin-bottom: 10px;
}

.register-container .register-component .registger-label {
    margin-bottom: 5px;
}

.charCounter {
    opacity: 60%;
    text-align: right;
    width: 100%;
}

.ap-combobox .ap-combobox-input-box .ap-tag.ap-tag-default.ap-tag-sm.combobox-tag {
    display: none;
}

.ap-combobox .ap-combobox-input-box .ap-combobox-clear-icon .icon-circle-delete-outline {
    display: none;
}

.ap-option-label span {
    color: black !important;
}

.user-not-found-info {
    font-style: italic;
    font-size: small;
    margin-right: 10px;
}

.register-container .ap-combobox .ap-combobox-input-box .ap-combobox-input::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: #7D7D7D;

}

.register-component .ap-tag {
    margin-right: 5px;
}

.register-component .ap-tag.ap-tag-default:not(.ap-tag-disabled) {
    background: #EB1C2C;
}

.register-container .checkbox {
    @apply ml-[60%] sm:ml-[35%] relative mt-[-25px] sm:absolute
}