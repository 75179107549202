
.login-flex-container div.logo {
  @apply ml-[105px] sm:ml-[39px] mt-[35px] mb-[20px] sm:mb-[187px]
}

.login-flex-container div.logo img {
  height: 50px;
}

.login-flex-container  {
  @apply flex flex-col sm:flex-row sm:justify-between w-[120%] h-[100%] sm:w-[100%]
}

.login-flex-container  .flex-child {
  align-items: center;
  justify-content: center;
}

div.login-box {
  @apply flex flex-col justify-center items-start ml-[10%] sm:ml-[20%] mr-[10%] w-[100%] sm:max-w-none mb-16 
}

.login-flex-container div.title,
.login-flex-container div.description,
.login-flex-container div.login {
  justify-content: center;
  align-items: center;
}

.login-flex-container div.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  margin-bottom: 30px;
}

.login-flex-container div.description {
  font-weight: 400px;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 30px;
}

.login-flex-container div.access-denied {
  font-weight: 400px;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 30px;
  color: #D83737;
}

.login-flex-container div.login button {
  @apply bg-[#D83737] text-white rounded-[4px] sm:w-[152px] w-[250%] h-[34px] text-[12px] leading-[17px] font-semibold
}

.login-flex-container img.pipe {
  height: 100%;
  width: 100%;
}

.login-flex-container .login-content {
  width: 35%;
  @apply w-[100%] sm:w-[35%]
}

.login-flex-container img.image_container {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.poweredby {
  margin-top: 40px;
  @apply hidden sm:block mt-[40px]
}