.tool-bar {
  display: flex;
  flex-direction: row-reverse;
}

/* .tooltip {
      position: relative;
      display: inline-block;
      border-bottom: 1px dotted black;
    } */

.tool-bar .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: transparent;
  color: grey;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-weight: 500;
  font-size: 12px;
  position: absolute;
  z-index: 1;
}

.question-richtext-container .question {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #221E1F;
}

.question-richtext-container .subtitle {
  display: flex;
  flex-direction: row;
  background: #F3F3F3;
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 8px;

}

.question-richtext-container .subtitle .subtitle-content {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  /* Pembina black */

  color: #221E1F;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.image-upload:hover .tooltiptext,
.video-upload:hover .tooltiptext {
  visibility: visible;
}

.image-upload>label>input {
  visibility: hidden;
  width: 0;
  height: 0;
}

.video-upload>label>input {
  visibility: hidden;
  width: 0;
  height: 0;
}


.question-richtext-container .title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.question-richtext-container .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  margin-bottom: 25px;
}

.question-richtext-container textarea {
  width: 100%;
  min-height: 200px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* or 150% */

  /* Pembina black */

  color: #221E1F;

}

.question-richtext-container textarea:focus {
  outline: none;
}

.question-richtext-container .ap-tooltip-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.question-richtext-container .question a {
  text-decoration: underline;
  color: #221E1F;
}