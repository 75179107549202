.like-modal .Appkit4-icon {
    font-size: 12px;
    margin-left: 90%;
    margin-right: auto;
}

.like-modal {
    width: 300px;
}

.like-modal .user-list {
    overflow-y: scroll;
    height: 350px;
}