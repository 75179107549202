.leaderboard-container {
    display: flex;
    flex-direction: column;
}

.leaderboard-container .title{
    font-weight: 400;
font-size: 24px;
line-height: 29px;
/* identical to box height */


/* Pembina black */

color: #221E1F;
margin-top: 20px;
margin-bottom: 20px;

}