
.training-content-container{
    @apply flex flex-col w-[90%] sm:w-[60%]  mb-8 sm:mb-4
}

.training-content-container .title{

    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    /* identical to box height */
    
    
    /* Pembina black */
    
    color: #221E1F;
}

.training-content-container .subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    
    
    /* Pembina black */
    
    color: #221E1F;
    margin-top:10px;
}

.training-content-container .contents {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;

   
    /* Pembina black */
    
    color: #221E1F;
    margin-top:15px;
    margin-bottom: 15px;
}

.training-content-container .footer{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    
    
    /* Pembina black */
    
    color: #221E1F;
    margin-top:10px;
    margin-bottom: 10px;
    
}

.training-content-container button {
    height: 34px;
    background: #EB1C2C;
    border-radius: 4px;
}

.training-content-container button span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    /* identical to box height, or 142% */
    
    text-align: center;
    
    /* Core/White */
    
    color: #FFFFFF;
    
}