

.profile-info{
    display:flex;
    gap:10px;
    flex-direction: column;
}
.profile-icons{
    display: flex;
    gap:10px;
    flex-direction: column;
}
.profile-text{
    display: flex;
    gap:25px;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
}
.p-item-identity{
    margin: auto;
}
.profile-item{
    display: flex;
    gap:10px;
    flex:1;
    font-size: 16px;
    font-weight: 500;
    padding:5px;
    border-radius: 5px;
}
.profile-item:hover{
    background-color: #eb1c2c;
    color:white;
    cursor: pointer;
}
.p-item-logout-img{
    width: 10%;
}
.p-item-profile-img{
    width: 10%;
}
.p-item-logout{
    margin-left: 10px;
}
.p-item-profile{
    margin-left: 0px !important;
}
.account-modal .ap-modal-header {
    display: none !important;
}

.ap-modal-header-icons-container .ap-modal-header-close-wrapper{
    display:none
}