.approval-container > .title {
    font-weight: 400;
font-size: 24px;
line-height: 29px;
/* identical to box height */


/* Pembina black */

color: #221E1F;
padding-left: 30px;
margin-bottom: 20px;
}

.approval-container > .bar{
    font-weight: 400;
font-size: 11px;
line-height: 13px;
/* identical to box height */


/* Core/Grey */

color: #7D7D7D;
    padding-left: 30px;
    margin-bottom: 20px;

}

.approval-container > .bar > span {
    color: #221E1F;
}

.approval-container .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.approval-container .buttons > button {
    margin-right: 10px;
}