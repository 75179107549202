.intake-container {
    display: flex;
    flex-direction: column;
    margin: 20px;
    max-width: 800px;
}

.intake-container .question-container {
    margin-top:20px;
}

.intake-container .button-container {
    display: flex;
    flex-direction:row;
    margin-top: 20px;
    margin-bottom: 20px;
}

.intake-container .button-container .question-button {
    margin-right: 10px;
}

.intake-container .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.intake-container .title-container .review-edit {
    margin-right: 15px;
    
}

.intake-container .title-container .review-edit  .ap-button-text:not(.in-loading):focus:not(.keyboard-focus) {
    outline: none;
    box-shadow: none !important;
}

.intake-container .title-container .review-edit .review-edit-button-active {
    color: red;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;    
    border-style: none;
}

.intake-container .title-container .review-edit .review-edit-button-inactive {
    color: #7D7D7D;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px; 
    border-style: none;   
}

.intake-container .title-container .review-edit .ap-button:hover {
    background-color: transparent !important;
}

.intake-container .bar {
  font-stretch: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
}
.intake-container .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    margin-top: 20px;
    margin-bottom: 2px;
}
.intake-container .subtitle {
    font-stretch: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: grey;
}