.request-list-container {
    display: flex;
    flex-direction: column;
}

.request-list-container .title {
    font-weight: 400;
font-size: 24px;
line-height: 29px;
/* identical to box height */


/* Pembina black */

color: #221E1F;
margin-bottom: 17px;
}


.request-list{
    display: flex;
    flex-direction: column;
}

.request-list .request-filter {
    display: flex;
    flex-direction: row;
}

.request-list .request-filter > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.request-list > div {
    margin-top: 10px;
}

.request-card-navigation{
    display: flex;
    flex-direction: column;

    width:600px;
    margin-top: 10px;
    padding:20px;
}
