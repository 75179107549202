.step-rating-group-questions-container .step-title{
    font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 18px;

/* Pembina black */

color: #221E1F;
margin-bottom: 10px;
}

.step-rating-group-questions-container .step-subtitle{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    
    
    /* Pembina black */
    
    color: #221E1F;
    margin-bottom: 10px;;
}


.rating-table-header {
    display: flex;
    flex-direction: row;
    width: 60%;
    min-width: 600px;
    height:50px;
}

.rating-table-body {
    width: 60%;
    min-width: 620px;
}

.rating-table-header div {
    display: flex;
    padding: 10px;
    /* justify-content: center; */
    align-items: center;
    font-weight: 500;
font-size: 14px;
line-height: 17px;

/* Core/White */

color: #FFFFFF;
    background: #EB1C2C;
/* Core/Light Grey */

border: 1px solid #DEDEDE;
border-radius: 4px 0px 0px 0px;
}

.rating-table-header-left {
  width:30%
}
.rating-table-header-right {
 width:40%
}

.rating-table-header-rational {
    width: 30%
}

.rating-container .ap-ratings .ap-ratings-item.selected{
   background-color: #EB1C2C;
}