.switch {
    margin-top: 2px;
    position: relative;
    display: inline-block;
    width: 36px;
    height: 18px;
    cursor: pointer;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
    content: "ON";
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 0.5px;
    bottom: 0.5px;
    background-color: rgb(255, 255, 255);
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #cacaca;
}

input:checked+.slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 24px;
}

.slider.round:before {
    border-radius: 24px;
}

.switch-text-problem-card {
    margin-left: 10px;
    color: #494949;
}

.switch-text-register {
    margin-left: 10px;
    font-weight: 500px;
}

.switch-text-gem {
    position: absolute;
    margin-left: 10px;
    margin-top: -1px;
    font-weight: 500px;
}

.switch-text-quickwins {
    position: absolute;
    margin-left: 10px;
    margin-top: -1px;
    font-weight: 500px;
}

.switch-text-strategic {
    position: absolute;
    margin-left: 10px;
    margin-top: -1px;
    font-weight: 500px;
}

.switch-text-true {
    color: #262626;
    font-size: 7px;
    margin-left: 5px;
    margin-top: 2px;
    position: absolute;
}

.switch-text-false {
    color: #ffffff;
    font-size: 7px;
    margin-left: 19px;
    margin-top: 2px;
    position: absolute;
}