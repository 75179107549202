.problem-view-container {
   display: flex;
   flex-direction: column;
   width: 100%;
   padding-left: 30px;
}

.problem-view-container .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    
    
    /* Pembina black */
    
    color: #221E1F;
}

.problem-view-container > hr {
    width: 100%;
}

.problem-view-container .horizontalLayout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.problem-view-container .verticalLayout{
    display: flex;
    flex-direction: column;
    margin-top:30px;

}

.problem-view-container .horizontalLayout  .field{
    display: flex;
    flex-direction: column;
    min-width: 200px;
    margin-top: 30px;
}
.problem-view-container .verticalLayout .field {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.problem-view-container .horizontalLayout  .title,
.problem-view-container .verticalLayout .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    
    
    /* Pembina black */
    
    color: #221E1F;
}

.problem-view-container .horizontalLayout  .content,
.problem-view-container .verticalLayout .content {
    font-weight: 400;
font-size: 14px;
line-height: 17px;

/* Pembina black */

color: #221E1F;
margin-top:8px;
  
}