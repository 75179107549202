.leaderboard-grid-container
{
    display: flex;
    flex-direction: column;
    
    /* height:100%; */
    width: 100%;
    height: 1000px;
    padding-left: 10px;
    padding-right: 30px;
    
}

.leaderboard-grid{
    min-height: 800px;
}

.leaderboard-grid-container  .leaderboard-filter {
    width: 100%;
    height: 100px;
    margin-top: 20px;
}

.leaderboard-grid-container .leaderboard-grid {
    width: 100%;
    height: 800px;
}

.leaderboard-grid-container .css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    
    letter-spacing: -0.4px;
    
    /* Core/White */
    
    color: #FFFFFF;
}

.MuiDataGrid-columnHeader--filledGroup {
    padding: 0 !important;
}

.MuiDataGrid-columnHeaders .css-z8fhq1-MuiDataGrid-columnHeaders {
    background-color: #EB1C2C;
}

.MuiTablePagination-root .MuiToolbar-root p {
    margin: auto;
}

.MuiDataGrid-columnSeparator {
    opacity: 0;
}


.MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: center;
    border-bottom-style: solid !important;
    border-bottom-color: #DEDEDE !important;
    border-bottom-width: 1px !important;
    padding: 0 !important;
    margin: 0;
}


.MuiDataGrid-iconSeparator {
    display: none !important;
}

.leaderboarder-grid-key-cell  div {
    font-weight: 500 !important;
font-size: 14px !important;
line-height: 17px;
/* identical to box height */

letter-spacing: -0.4px;

/* Pembina Red */

color: #EB1C2C;
}

.leaderboarder-grid-solved-cell{
    background-color: rgba(235, 28, 44, 0.05);
}

.leaderboard-grid-container .MuiDataGrid-columnHeaders {
    border-radius: 8px 8px 0px 0px !important;
}


.leaderboard-grid-container .MuiButton-text {
    color: #EB1C2C !important;
}

