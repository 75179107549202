
 .ap-pagination {
   justify-content: center;
   margin-top: 65px;
} 
.preferences{
    @apply flex m-auto opacity-[60%] text-xs cursor-pointer sm:text-[15px] justify-center sm:m-1
}
.filter{
    display: flex;
    margin-bottom: 10px;
}
.filter .preferences{
    flex:0.1;
}
.filter .dropdown-list{
    flex:1;

}
.ap-field .ap-field-container .ap-field-wrapper {
    height: 2.75rem;
}
.loader{
    margin:auto;
}
.dashboard-container .problem-category-small {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width:25%;
    max-height: 15%;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    border-color: #f73b3b;
    margin-right: 30px;
    padding:10px;
}
.no-records{
    @apply flex justify-center sm:mr-[70px] sm:mb-[350px] opacity-[60%] sm:text-[15px] text-xs pt-[10px] mb-1
}
/* .loader-scale{
    scale:1;
} */

.ap-loading .ap-loading-circular .mask .progress-bar {
    clip: rect(0px, calc(var(--circularLoadingWidth) / 2), var(--circularLoadingWidth), 0px);
    background-color: #c52a1a !important;
}
.problems-list{
    height: 90%;
}