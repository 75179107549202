.requests-container {
    display: flex;
    flex-direction: column;
}

.requests-container .title {
    font-weight: 400;
font-size: 24px;
line-height: 29px;
/* identical to box height */


/* Pembina black */

color: #221E1F;
margin-bottom: 17px;
}

.requests-container .ap-tabset-container .ap-tabset-toggle-wrapper.underline .ap-tabset-toggle.active .ap-tabset-toggle-active {
    color: #EB1C2C;
}

.requests-container .ap-tabset-container .ap-tabset-toggle-wrapper.underline .ap-tabset-toggle.active .ap-tabset-toggle-underline {
    background-color: #EB1C2C;
    height: 0.2rem;
}