.grid-drop-cell {
    display: flex;
    justify-items: center;
    align-items: center;
    padding-left: 10px;
    width:100%;
    height: 100%;
}

.grid-drop-cell .dragger-question-container {
    width:100%;
}

.grid-drop-cell > .draggable-container {
    background: rgba(235, 28, 44, 0.05);
    /* Core/Light Grey */
    
    border: 1px solid #DEDEDE;
    border-radius: 4px;
    width: 80%;
    margin: auto;
}