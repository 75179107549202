.quickwins-confirm {
    @apply w-[340px] sm:w-[600px] h-[250px]
}

.quickwins-confirm .description {
    padding-top: 20px;
    padding-bottom: 30px;
}

.quickwins-confirm-button{
    margin-left: 10px;
}