.dragdrop-container{
  display: flex;
  flex-direction: column;
}

.dragdrop-container  .step-title{

font-weight: 700;
font-size: 16px;
line-height: 18px;

/* Pembina black */

color: #221E1F;
margin-bottom: 10px;

}


.drag-drop {
    display: flex;
    flex-direction:  row;
}

.dragdrop-container .drag-drop .draggable {
    display: flex;
    flex-direction: column;
    width: 30%;
    /* padding: 20px; */
}

.dragdrop-container  .drag-drop .droppable {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-right: 20px;
    padding: 20px;
}

.drag-drop .droppable .question-cell-container  .column-header {
    height: 50px;
  }

.drag-drop .droppable .question-cell-container  .question-cell {
  height: 70px;
}


.draggable  .drag-instruction {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #F3F3F3;
  border-radius: 4px;
  width: 360px;
  margin:10px;
 
  
}

 .draggable  .drag-instruction .drag-instructino-content{
  margin-left: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  
  /* Pembina black */
  
  color: #221E1F;
}