.image-comment-view{
    display: flex;
}
.right-side{
    padding: 10px;
    
}
.left-side{
    width: fit-content;
    flex: 0 0 25em;
    width: fit-content;
    height: fit-content;
}
.loader{
    margin:auto;
}
.loader-scale{
    scale:3;
}

.ap-loading .ap-loading-circular .mask .progress-bar {
    clip: rect(0px, calc(var(--circularLoadingWidth) / 2), var(--circularLoadingWidth), 0px);
    background-color: #c52a1a !important;
}
.like-container {
    padding-top: 10px;
    padding-bottom: 10px;
}

.problem-modal {
    @apply w-[24.75rem] sm:w-[63.75rem] h-[600px]
}

.close-button {
    margin-top: 10px;
}

.problem-header {
    font-size: 22px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: -10px;
    color: #c52a1a;
    text-decoration: underline;
}

.problem-statement {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
    
    color: #221E1F;  
}