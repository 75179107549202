.team-member-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 10px;
}

.team-member-title {
    @apply font-normal text-[18px] sm:text-[24px] leading-[26px] 
}

.team-member-container .team-member-save {
    color: #EB1C2C !important;
    background-color: transparent;
    box-shadow: inset 0 0 0 .0625rem #EB1C2C !important;
}

.team-member-container>div {
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    ;
}

.team-member-container .tag-user-component .ap-combobox-multiple {
    min-width: 100px !important;
}

.team-member-container .tag-user-component .ap-combobox .ap-combobox-input-box {
    height: 40px !important;
}

.team-member-container .tag-user-component .tag-user-list>div {
    margin-top: 5px;
    margin-left: 5px;
}